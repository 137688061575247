import { graphql } from 'gatsby';
import { useEffect } from 'react';
import { PageWrapper } from '../components/Layout/PageWrapper';
import { Hero } from '../components/Layout/Hero';
// import { CategoriesMenu } from '../components/Layout/Blog/CategoriesMenu';
import { ArticleCard } from '../components/Layout/Blog/Cards/ArticleCard';
import { TourCard } from '../components/Layout/Tour/Cards/TourCard';
import { SectionGridThreeCols } from '../components/Layout/sharedStyles/sectionStyles';
import RegiondoScript from '../components/RegiondoScript';

const TourRootTemplate = ({
  data: {
    datoCmsTourRoot: {
      hero: [{ heroTitle, heroSubtitle }],
      widgetid,
      seo,
    },
    allDatoCmsTour: { tourNodes },
  },
  pageContext
}) => 



(
  <PageWrapper
    pageData={pageContext}
    seoTitle={seo?.seoTitle}
    seoDescription={seo?.seoDescription}
    seoImage={seo?.seoImage?.seoImageUrl}
    seo
  >
    <Hero title={heroTitle} subtitle={heroSubtitle} />
    <product-catalog-widget widget-id={widgetid}></product-catalog-widget>
    <RegiondoScript/>
    {/* <SectionGridThreeCols>
      {tourNodes.map(
        ({ id, coverImage, title, subtitle, difficulty, duration, price  }) => (
          <TourCard
            key={id}
            recordId={id}
            difficulty={difficulty}
            duration={duration}
            price={price}
            cardImg={coverImage.gatsbyImageData}
            cardImgMobile={coverImage.squaredImage}
            altImg={title}
            title={title}
            excerpt={subtitle}
          />
        )
      )}
    </SectionGridThreeCols> */}
  </PageWrapper>
);

export default TourRootTemplate;

// Main query

export const query = graphql`
query TourRootQuery($locale: String!) {
  datoCmsTourRoot(locale: { eq: $locale }) {
    locale
    seo {
      seoTitle: title
      seoDescription: description
      seoImage: image {
        seoImageUrl: url
      }
    }
    hero {
      heroTitle
      heroSubtitle
    }
    widgetid
  }
  allDatoCmsTour(
    filter: { locale: { eq: $locale }, noTranslate: { ne: true } }
  ) {
    tourNodes: nodes {
      locale
      difficulty
      duration
      price
      id: originalId
      title
      subtitle
      coverImage {
        gatsbyImageData(
          width: 280
          height: 200
          placeholder: NONE
          forceBlurhash: false
        )
        squaredImage: gatsbyImageData(
          width: 100
          height: 100
          imgixParams: { ar: "1", fit: "crop" }
        )
      }
    }
  }
}

#allDatoCmsTour: { tourNodes }`;
